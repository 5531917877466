import React from 'react'
import { css } from '@emotion/react'
import AppColor from '~/libs/AppColor'
import { Link, navigate } from 'gatsby'
import AppSize from '~/libs/AppSize'
import BrainLargeRedImage from '~/images/brainLargeRed.png'
import BrainLargeBlueImage from '~/images/brainLargeBlue.png'
import PcImg1 from '~/images/content/i1.png'
import PcImg2 from '~/images/content/i2.png'
import PcImg3 from '~/images/content/i3.png'
import PcImg4 from '~/images/content/i4.png'
import PcImg5 from '~/images/content/i5.png'
import PcImg6 from '~/images/content/i6.png'
import BgBoardImage from '~/images/bgBoard.png'

const PreLoader: React.FC = () => {
  return (
    <>
      <div css={hideCss}>
        <img src={BgBoardImage} />
        <img src={BrainLargeRedImage} />
        <img src={BrainLargeBlueImage} />
        <img src={PcImg1} />
      </div>
    </>
  )
}
export default PreLoader

const hideCss = css`
  height: 1px;
  width: 1px;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
`
